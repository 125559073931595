import {Controller} from "stimulus";
import autoComplete from "@tarekraafat/autocomplete.js/dist/autoComplete";


export default class extends Controller {
  static targets = ["dropdown", "quantity", "quantitydum", "sellingPrice", "price", "ctn", "pcs", "packing"]
  static values = { render: Boolean, url: { type: String, default: '' }, populate: Boolean, quantity: Number, results: Array }

  connect(){
    console.log(this.urlValue)
    if (this.hasDropdownTarget){
      this.setupSlimSelect(this.dropdownTarget);
    }
  }

  selectChange() {
    this.quantityUpdated();
    this.dispatch('selectChange');
  }

  setupSlimSelect(element) {

    const autoCompleteJS = new autoComplete({
      selector: `#${this.dropdownTarget.id}`,
      placeHolder: "Search",
      searchEngine: function(query, record){
        let answer = record.split(' / ')[1]
        if (answer.toLowerCase().startsWith(query.toLowerCase())) {
          return record;
        }
    },
      data: {
        src: async (query) => {
          try {

            const loadedList = localStorage.getItem('items')
            if(loadedList == null){
              const source = await fetch(this.urlValue);
              const data = await source.json();
              localStorage.setItem('items', JSON.stringify(data))
              return data;
            } else {
              return JSON.parse(loadedList)
            }
          } catch (error) {

            return error;
          }
        },
        keys: ["text"],
        cache: true,
      },
      resultsList: {
        element: (list, data) => {
          if (!data.results.length) {
            // Create "No Results" message element
            const message = document.createElement("div");
            // Add class to the created element
            message.setAttribute("class", "no_result");
            // Add message text content
            message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
            // Append message element to the results list
            list.prepend(message);
          }
        },
        noResults: true,
      },
      resultItem: {
        highlight: true,
      },
    });



    autoCompleteJS.input.addEventListener("selection", function (event) {
      const feedback = event.detail;
      autoCompleteJS.input.value = feedback.selection.value[feedback.selection.key];
      let elementId = $(this).attr('id').replace('temp_item_id','');
      $(`#${elementId}quantity`).val();
      $(`#${elementId}item_id`).val(feedback.selection.value["id"]);
      $(`#${elementId}packing`).val(feedback.selection.value["packing"]);
      $(`#${elementId}price`).val(feedback.selection.value['price']);
      $(`#${elementId}total_price`).val(feedback.selection.value['price']);
      $(`label[for*='${elementId}quantity']`).html(feedback.selection.value['quantity'] + 'Packing: ' + feedback.selection.value['packing']);
      // let itemBatchPath = `/items/${feedback.selection.value["id"]}.json`;

      // clear value of input


      $(`#${elementId}ctn`).val('')
      $(`#${elementId}ctn`).focus();
      // autoCompleteJS.input.focus();

      // console.log(feedback.selection.value);
    });
  }

  quantityUpdated(){
    this.quantitydumTarget.value = (this.filterNumber(this.packingTarget.value) * this.filterNumber(this.ctnTarget.value)) + this.filterNumber(this.pcsTarget.value);
    this.sellingPriceTarget.value = this.filterNumber(this.quantitydumTarget.value) * this.filterNumber(this.priceTarget.value);
    this.quantityTarget.value = this.quantitydumTarget.value;
    this.dispatch('invoiceLineUpdated');
  }

  filterNumber(num){
    return parseFloat(num) || 0
  }
}